<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="manhour">
		<div class="manhour-tab">
			<div @click="changeTab('0')" class="manhour-tab-item">
				<span :class="{'tab-item-active': currentTab == '0'}">未提交</span>
			</div>
			<div @click="changeTab('1')" class="manhour-tab-item">
				<span :class="{'tab-item-active': currentTab == '1'}">已提交</span>
			</div>
		</div>
		<div class="manhour-data">
			<van-pull-refresh class="" v-model="fresh" @refresh="onRefresh">
				<van-list offset="10" v-model="loadMore" :finished="loadFinish"
					:finished-text="jobData.length >= 4 ?'没有更多了':''" @load="scrollToLower">
					<div class="manhour-ipt">
						<img src="@/assets/images/mobile/common/search.png" alt="">
						<form style="flex: 1;" action="javascript:return true;" @submit.prevent="">
							<input @search="searchFn()" v-model.trim="jonName" confirm-type="search" type="search"
								placeholder="请输入岗位名称查询">
						</form>
					</div>
					<div class="manhour-filter">
						<div @click="datePickerFn(true)" class="manhour-filter-left">
							<img src="@/assets/images/mobile/common/filter.png" alt="">
							<span v-if="!postStarTime&&!postEndTime">月份筛选</span>
							<span v-else>{{startDate[0]}}/{{startDate[1]}}-{{endDate[0]}}/{{endDate[1]}}</span>
						</div>
						<div class="manhour-filter-right-flex">
							<!-- <div @click="datePickerFn(true)" class="manhour-filter-left">
								<img src="@/assets/images/mobile/common/filter.png" alt="">
								<span >校区</span>
							</div> -->
							<div @click="showTypeSearch('school')" class="list-search-right" style="margin-right:0.32rem">
								<img src="@/assets/images/mobile/common/filter.png" alt="">
								<span>校区</span>
								<div v-if="isShowSchool" class="list-search-list">
									<span :class="{'search-list-active': item.id == currentSchoolType}"
										v-for="(item, index) in schoolList" :key="index"
										@click.stop="handleSearch(item,'school')">{{item.name}}</span>
								</div>
							</div>
							<div @click="showTypeSearch('post')" class="list-search-right" style="margin-right:0.32rem">
								<img src="@/assets/images/mobile/common/filter.png" alt="">
								<span>岗位</span>
								<div v-if="isShowType" class="list-search-list">
									<span :class="{'search-list-active': item.type == currentType}"
										v-for="(item, index) in searchList" :key="index"
										@click.stop="handleSearch(item,'post')">{{item.title}}</span>
								</div>
							</div>
							<!-- <div @click="datePickerFn(true)" class="manhour-filter-left">
								<img src="@/assets/images/mobile/common/filter.png" alt="">
								<span>岗位</span>
							</div> -->
							<div @click="downLoadFile" class="manhour-filter-right">
								<img src="@/assets/images/mobile/common/download.png" alt="">
								<span>导出数据</span>
							</div>
						</div>
						
					</div>
					<jobList :importArea="'teacher'" :jobList="jobData" :showEmpty="showEmpty" />
				</van-list>
			</van-pull-refresh>
		</div>
		<div v-if="showDatePicker" class="manhour-picker">
			<div class="manhour-picker-inner">
				<div class="picker-inner-title">
					<span>日期筛选</span>
					<img @click="datePickerFn(false)" src="@/assets/images/mobile/common/cha.png" alt="">
				</div>
				<div class="picker-inner-tip">
					请选择自定义时间
				</div>
				<div class="picker-inner-date">
					<span @click="changePicker(0)" class="inner-date-val"
						:class="{'inner-date-active': currentPicker == 0}">{{startDate[0]}}年{{startDate[1]}}月</span>
					<span class="inner-date-line">-</span>
					<span @click="changePicker(1)" class="inner-date-val"
						:class="{'inner-date-active': currentPicker == 1}">{{endDate[0]}}年{{endDate[1]}}月</span>
				</div>
				<van-picker @change="dateChange" item-height="0.88rem" title="标题" :columns="dateColumns"
					:visible-item-count="3" />
				<div @click="confirmDate" class="picker-inner-btn">
					确定
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import jobList from "../components/jobList.vue";
	import {
		manhourListMb,
		manhourDownloadMb,
	} from "@/api/mobile/manhour";
	import {
		jobListMb,
	} from "@/api/mobile/activity";
	import {
		schoollistAllPc
	} from '@/api/pc/index'
	import storage from "store";
	export default {
		components: {
			jobList
		},
		data() {
			return {
				schoolList: [],
				searchList: [{
						type: 1,
						title: "校内固定",
						value: "S_FIX"
					},
					{
						type: 2,
						title: "校内临时",
						value: "S_TEMP"
					}
				],
				currentTab: '0',
				currentSchoolType:-1,
				startDate: [],
				endDate: [],
				jonName: "",
				currentPicker: 0,
				showDatePicker: false,
				dateColumns: [
					// 第一列
					{
						values: [],
						defaultIndex: 0,
					},
					// 第二列
					{
						values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', ],
						defaultIndex: 0,
					},
				],
				jobData: [],
				pageIndex: 1,
				pageSize: 6,
				totalCount: 0,
				fresh: false,
				loadMore: false,
				loadFinish: false,
				showEmpty: false,
				postStarTime:"",
				postEndTime:"",
				postType:'',
				schoolId:"",
				currentType:-1,
				isShowType: false,
				isShowSchool:false
				// xlsxUrl: "http://121.41.38.209:8080/zjut-work-study-server/post/export.xlsx?name=&postStarTime=&postEndTime=&workingHourStatus=",
				// xlsUrl: "http://peer-circle.oss-cn-hangzhou.aliyuncs.com/misc/202207/a2bf3861f92c4a77838792c926a71fe9.xlsx",
				// docxUrl: "https://yourise-dev.oss-cn-hangzhou.aliyuncs.com/misc/202208/94fc8c17438c4d43aed16dc6ea10805d/一个亿项目.docx"
			}
		},
		mounted() {

			let date = new Date()
			let currentYear = date.getFullYear()
			let currentMonth = date.getMonth() + 1
			let monthColumn = this.dateColumns[1].values
			let monthIndex = monthColumn.findIndex(item => item * 1 == currentMonth * 1)
			this.dateColumns[1].defaultIndex = monthIndex
			let yearList = []
			for (let h = currentYear - 10; h < currentYear + 10; h++) {
				yearList.push(h)
			}
			for (let i = currentYear; i <= currentYear + 10; i++) {
				yearList.push(i)
			}
			this.dateColumns[0].values = [...new Set(yearList)].sort((a, b) => a - b)
			let yearColumn = this.dateColumns[0].values
			let yearIndex = yearColumn.findIndex(item => item * 1 == currentYear * 1)
			this.dateColumns[0].defaultIndex = yearIndex
			this.startDate = [currentYear, currentMonth]
			this.endDate = [currentYear, currentMonth + 1]
			this.getJobData()
			// storage.remove("accessManhour")
			storage.remove("signUpData")
			this.getSchoolList()
			console.log(this.startDate)
		},
		methods: {
			//获取校区列表
			getSchoolList() {
				let params = {
					pageIndex: 1,
					pageSize: 999
				}
				schoollistAllPc(params).then((res) => {
					if (res.code == 0) {
						this.schoolList = res.data
					}
				})
			},
			showTypeSearch(type) {
				if(type==='school'){
					this.isShowSchool = !this.isShowSchool
				}else{
					this.isShowType = !this.isShowType
				}
			},
			//点击筛选
			handleSearch(item,type) {
				this.pageIndex = 1
				this.jobData = []
				if(type==='post'){
					if (this.postType == item.value) {
						this.postType = ""
						this.currentType = -1
					} else {
						this.postType = item.value
						this.currentType = item.type
					}
				}else{
					if (this.schoolId == item.id) {
						this.schoolId= ""
						this.currentSchoolType = -1
					} else {
						this.schoolId = item.id
						this.currentSchoolType = item.id
					}
				}
				// if (this.postType == item.value) {
				// 	this.postType = ""
				// 	this.currentType = -1
				// } else {
				// 	this.postType = item.value
				// 	this.currentType = item.type
				// }
				this.getJobData()
			},
			getMonthDays(intYear, intDay) {
				return new Date(intYear, intDay, 0).getDate()
			},
			getJobData() {
				return new Promise((resolve, reject) => {
					this.loadFinish = false
					this.showEmpty = false
					let params = {
						pageIndex: this.pageIndex,
						pageSize: this.pageSize,
						workingHourStatus: this.currentTab,
						postStarTime:this.postStarTime,
						postEndTime:this.postEndTime,
						schoolId:this.schoolId,
						postType:this.postType
					}
					// if (this.startDate.length > 0) {
					// 	this.$set(params, "postStarTime", `${this.startDate[0]}-${this.startDate[1]}-01`)
					// }
					// if (this.endDate.length > 0) {
					// 	this.$set(params, "postEndTime", `${this.endDate[0]}-${this.endDate[1]}-${this.getMonthDays(this.endDate[0], this.endDate[1])}`)
					// }
					if (this.jonName) {
						this.$set(params, "name", this.jonName)
					}
					jobListMb(params).then((res) => {
						console.log("getJobData", this.startDate,this.endDate)
						if (res.code == 0) {
							if (this.pageIndex == 1) {
								this.fresh = false;
							}
							this.loadMore = false
							this.jobData = this.jobData.concat(res.data)
							this.totalCount = res.page.totalCount
							if (this.jobData.length >= res.page.totalCount * 1) {
								this.loadFinish = true
							}
							if (this.jobData.length == 0) {
								this.showEmpty = true
							}
							this.pageIndex++
							resolve()
						} else {
							this.loadMore = false
							this.loadFinish = true
							this.fresh = false
							this.showEmpty = false
							reject()
						}
					}).catch((err) => {
						this.loadMore = false
						this.loadFinish = true
						this.fresh = false
						this.showEmpty = false
						reject()
					})
				})
			},
			onRefresh() {
				this.pageIndex = 1
				setTimeout(() => {
					this.jobData = []
					this.getJobData()
				}, 1000);
			},
			scrollToLower() {
				// 加载状态结束
				if (this.totalCount > this.pageSize && this.pageIndex > 1) {
					this.getJobData()
				}
			},
			changeTab(num) {
				this.currentTab = num
				this.pageIndex = 1
				this.jobData = []
				this.getJobData()
			},
			datePickerFn(boolen) {
				this.showDatePicker = boolen
			},
			changePicker(num) {
				this.currentPicker = num
			},
			dateChange(e, current) {
				console.log("dateChange", current)
				if (this.currentPicker == 0) {
					this.startDate = current
				} else {
					this.endDate = current
				}
			},
			confirmDate() {
				console.log("confirmDate", this.startDate, this.endDate)
				this.pageIndex = 1
				this.jobData = []
				if (this.startDate.length > 0) {
				this.postStarTime = `${this.startDate[0]}-${this.startDate[1]}-01`
					// this.$set(params, "postStarTime", `${this.startDate[0]}-${this.startDate[1]}-01`)
				}
				if (this.endDate.length > 0) {
					this.postEndTime = `${this.endDate[0]}-${this.endDate[1]}-${this.getMonthDays(this.endDate[0], this.endDate[1])}`
					// this.$set(params, "postEndTime", `${this.endDate[0]}-${this.endDate[1]}-${this.getMonthDays(this.endDate[0], this.endDate[1])}`)
				}
				this.getJobData()
				this.showDatePicker = false
			},
			searchFn() {
				this.pageIndex = 1
				this.jobData = []
				this.getJobData()
				document.activeElement.blur();
			},
			downLoadFile() {
				// 打开新页面
				if (this.$dd.env.platform !== "notInDingTalk") {
					let exportUrl =
						`${process.env.VUE_APP_BASE_API}/post/export.xlsx?${storage.get('accessTokenKey')}=${storage.get('accessToken')}`
					let paramsStr = ""
					if(this.postStarTime){
						paramsStr += `&&postStarTime=${this.postStarTime}`
					}
					if(this.postStarTime){
						paramsStr += `&&postEndTime=${this.postEndTime}`
					}
					// if (this.startDate.length > 0) {
					// 	paramsStr += `&&postStarTime=${this.startDate[0]}-${this.startDate[1]}-01`
					// }
					// if (this.endDate.length > 0) {
					// 	paramsStr += `&&postEndTime=${this.endDate[0]}-${this.endDate[1]}-${this.getMonthDays(this.endDate[0], this.endDate[1])}`
					// }
					if (this.jonName) {
						paramsStr += `&&name=${this.jonName}`
					}
					if(this.postType){
						paramsStr += `&&postType=${this.postType}`
					}
					if(this.schoolId){
						paramsStr += `&&schoolId=${this.schoolId}`
					}
					paramsStr += `&&workingHourStatus=${this.currentTab}`
					exportUrl += paramsStr
					console.log("downLoadFile", exportUrl)
					if (this.$dd.env.platform !== "notInDingTalk") {
						this.$dd.ready(() => {
							this.$dd.biz.util.openLink({
								url: exportUrl, //要打开链接的地址
								onSuccess: function(result) {
									/**/
								},
								onFail: function(err) {}
							})
						})
					}
				}
			}
		},
	}
</script>
<style lang="scss" scoped>
.list-search-right {
		display: flex;
		align-items: center;
		position: relative;

		span {
			font-size: 0.28rem;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 0.4rem;
			color: #606266;
		}

		img {
			width: 0.28rem;
			height: 0.28rem;
			margin-right: 0.16rem;
		}

		.list-search-list {
			position: absolute;
			top: 0.5rem;
			right: 0rem;
			display: flex;
			flex-direction: column;
			width: 1.76rem;
			background: #FFFFFF;
			box-shadow: 0rem 0.04rem 0.08rem #DCDFE6;
			border-radius: 0.08rem;

			span {
				width: 1.76rem;
				font-size: 0.26rem;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 0.36rem;
				color: #303133;
				border-bottom: 1px solid #DCDFE6;
				padding: 0.16rem 0.16rem;
				box-sizing: border-box;
			}

			.search-list-active {
				color: #4392FA;
			}
		}

		.list-search-list :last-child {
			border: 0;
		}
	}
	.manhour {
		height: 100%;
		display: flex;
		flex-direction: column;

		.manhour-tab {
			display: flex;
			align-items: center;
			background: #FFFFFF;

			.manhour-tab-item {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;

				span {
					font-size: 0.32rem;
					font-family: PingFang SC;
					font-weight: 400;
					color: #303133;
					display: inline-block;
					line-height: 0.96rem;

				}

				.tab-item-active {
					color: #4392FA;
					border-bottom: 0.04rem solid #4392FA;
				}
			}
		}

		.manhour-data {
			flex: 1;
			overflow-y: scroll;

			.manhour-ipt {
				width: 6.94rem;
				height: 0.8rem;
				background: #FFFFFF;
				box-shadow: 0rem 0.04rem 0.16rem rgba(204, 204, 204, 0.1600);
				border-radius: 0.12rem;
				margin: 0.2rem 0.28rem;
				display: flex;
				align-items: center;
				padding: 0 0.2rem;
				box-sizing: border-box;

				img {
					width: 0.28rem;
					height: 0.28rem;
					margin-right: 0.16rem;
				}

				input {
					width: 100%;
					outline: none;
					border: 0;
				}
			}

			.manhour-filter {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 0.2rem;
				box-sizing: border-box;
				margin-bottom: 0.08rem;
				.manhour-filter-right-flex{
					display: flex;
					align-items: center;
					justify-content: flex-end;
				}
				.manhour-filter-left {
					display: flex;
					align-items: center;
					margin-right:0.32rem;

					img {
						width: 0.28rem;
						height: 0.28rem;
						margin-right: 0.16rem;
					}

					span {
						font-size: 0.28rem;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 0.4rem;
						color: #606266;
					}
				}

				.manhour-filter-right {
					display: flex;
					align-items: center;

					img {
						width: 0.28rem;
						height: 0.28rem;
						margin-right: 0.16rem;
					}

					span {
						font-size: 0.28rem;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 0.4rem;
						color: #909399;
					}
				}
			}
		}


		.manhour-picker {
			position: fixed;
			width: 100%;
			height: 100vh;
			// padding-bottom:o.8rem;
			background-color: rgba(0, 0, 0, 0.6);
			bottom: 0.8rem;
			left: 0;
			z-index:999999;
			.manhour-picker-inner {
				width: 100%;
				height: 7.2rem;
				background: #FFFFFF;
				border-radius: 0.16rem 0.16rem 0rem 0rem;
				position: absolute;
				bottom: 0;
				display: flex;
				flex-direction: column;
				padding: 0.28rem 0.4rem;
				box-sizing: border-box;

				.picker-inner-title {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 0.28rem;

					span {
						font-size: 0.32rem;
						font-family: PingFang SC;
						font-weight: bold;
						line-height: 0.44rem;
						color: #303133;
					}

					img {
						width: 0.32rem;
						height: 0.32rem;
					}
				}

				.picker-inner-tip {
					font-size: 0.28rem;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 0.4rem;
					color: #303133;
					margin-bottom: 0.2rem;
				}

				.picker-inner-date {
					display: flex;
					align-items: center;

					.inner-date-val {
						flex: 1;
						height: 0.8rem;
						background: #F7F8F9;
						border-radius: 0.04rem;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 0.28rem;
						font-family: PingFang SC;
						font-weight: 400;
						color: #B4BCC6;
					}

					.inner-date-active {
						color: #303133;
					}

					.inner-date-line {
						width: 0.7rem;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}

				.picker-inner-btn {
					width: 6.94rem;
					height: 0.8rem;
					background: #4392FA;
					border-radius: 0.08rem;
					font-size: 0.28rem;
					font-family: PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 0.4rem;
				}
			}
		}
	}
</style>
